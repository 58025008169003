
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import ChartFilterDropdown from "@/components/ChartFilterDropdown.vue"

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    Dropdown1,
    //ChartFilterDropdown
    
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
});
