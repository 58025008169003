
import { defineComponent, onMounted,computed } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    var user = computed(() => store.getters.currentUser)

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      t,
      version,
      user
    };
  },
});
