import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "header-menu align-items-stretch",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "header-menu",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "end",
  "data-kt-drawer-toggle": "#kt_header_menu_mobile_toggle",
  "data-kt-place": "true",
  "data-kt-place-mode": "prepend",
  "data-kt-place-parent": "{default: '#kt_body', lg: '#kt_header_nav'}"
}
const _hoisted_2 = {
  class: "\r\n        menu\r\n        menu-lg-rounded\r\n        menu-column\r\n        menu-lg-row\r\n        menu-state-bg\r\n        menu-title-gray-700\r\n        menu-state-title-primary\r\n        menu-state-icon-primary\r\n        menu-state-bullet-primary\r\n        menu-arrow-gray-400\r\n        fw-bold\r\n        my-5 my-lg-0\r\n        align-items-stretch\r\n      ",
  id: "#kt_header_menu",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "menu-item me-lg-1"
}
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MainMenuConfig, (item, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (!item.heading)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.pages, (menuItem, j) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
                  (menuItem.heading && ((menuItem.route!='/templateadministration') ||( _ctx.user.role = 'Administrators') ))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_router_link, {
                          to: menuItem.route
                        }, {
                          default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                            _createElementVNode("a", {
                              href: href,
                              class: _normalizeClass(["menu-link py-3", [isActive && 'active', isExactActive && 'active']]),
                              onClick: navigate
                            }, [
                              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t(menuItem.heading)), 1)
                            ], 10, _hoisted_4)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}