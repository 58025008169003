
import { defineComponent, onMounted, ref } from "vue";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import Dropdown4 from "@/components/dropdown/Dropdown4.vue";
import * as Mast from "@/models/MasterModel";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "drawer-alarm",
  components: {
    //Datatable,
    //Dropdown4,
    // ErrorMessage,
    // Field,
    // Form,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    let today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const searchFilter = ref<Mast.AlarmEventSearchRequest>({
      searchString: null,
      parentIds: null,
      pageSize: 100,
      pageNumber: null,
      currentPageNumber: 1,
      startDate: yesterday.toISOString().substring(0, 16),
      endDate: today.toISOString().substring(0, 16),
      //alarmEventTypes: null,
      states: [],
      userAcknowledge: [],
    });

    const validationSchema = Yup.object().shape({
      //unit: Yup.string().required().label("Unit"),
      //unitDateRange: Yup.string().label("unitDateRange"),
      startDate: Yup.date().label("Period start"),
      endDate: Yup.date().label("Period end"),
    });

    const alarmEventStates = ref<Mast.GenericOption[]>([
      {} as Mast.GenericOption,
    ]);
    const alarmAcknowledgeStates = ref<Mast.GenericOption[]>([
      {} as Mast.GenericOption,
    ]);

    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Alarm",
        key: "name",
        sortingField: "alarm.displayName",
        sortable: true,
      },
      {
        name: "Start",
        key: "start",
        sortable: true,
      },
      {
        name: "startValue",
        key: "startValue",
        sortable: true,
      },
      {
        name: "end",
        key: "end",
        sortable: true,
      },
      {
        name: "endValue",
        key: "endValue",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const alarmHistory = ref<Array<Mast.AlarmHistory>>([
      // {
      //   Id: 1,
      //   alarm: { displayName: "aap" } as Mast.Alarm,
      //   start: "01-01-2001 12:12",
      //   end: "01-01-2001 12:15",
      //   startValue: 5,
      //   endValue: 10,
      //   UserAcknowledge: {} as Mast.GenericOption,
      //   status:"info",
      //   alarmCount:0,
      // },
      // {
      //   Id: 3,
      //   alarm: { displayName: "Beer" } as Mast.Alarm,
      //   start: "01-02-2001 12:12",
      //   end: "01-02-2001 12:15",
      //   startValue: 7,
      //   endValue: 2,
      //   UserAcknowledge: {} as Mast.GenericOption,
      //   status:"warning",
      //   alarmCount:0,
      // },
    ]);

    const getAlarmHistory = () => {
      const postData = {
        searchString: null,
        parentIds: null,
        pageSize: 100, //todo geert is dit genoeg
        pageNumber: null,
      } as any;
      return (
        ApiService.post("/device/alarmevents", postData)
          //ApiService.post("/device/alarmevents", searchFilter.value as any)
          .then(({ data }) => {
            console.log("alarmHistory response data:");
            console.log(data);
            console.log(data.results);
            alarmHistory.value = data.results;
          })
          .catch(({ response }) => {
            console.log("Fout getMasters");
          })
      );
    };

    const clickAcknowledge = (item) => {
      console.log("acknowledge");
      if (!(item.id > 0)) {
        console.log(" Fout acknowledge ID  leeg");
        return;
      }

      ApiService.get2("/device/alarmacknowledgement?id=" + item.id)
        .then(({ data }) => {
          getAlarmHistory();
        })
        .catch(({ response }) => {
          console.log("Fout alarmacknowlegdement");
        });
    };

    const filterChanged = () => {
      console.log("refresh filter");
      getAlarmHistory();
    };

    const clickViewAll = ()=>{
      console.log("goto alarms")
      router.push({ name: "alarms"});
      //router.push({ name: "alarms" });
    }

    onMounted(() => {
      console.log("Mount alarmdrawer");
      BnPHelpers.getOptions("/device/alarmeventstates", alarmEventStates);
      BnPHelpers.getOptions(
        "/device/alarmacknowledgestates",
        alarmAcknowledgeStates
      );

      getAlarmHistory();
    });

    return {
      //alarmEvents,
      alarmHistory,
      tableHeader,
      checkedCustomers,
      getAlarmHistory,
      clickAcknowledge,
      filterChanged,
      validationSchema,
      searchFilter,
      alarmEventStates,
      alarmAcknowledgeStates,
      clickViewAll,
      t,
    };
  },
});
