
import { defineComponent,computed } from "vue";
//import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import alarmBtn from "@/components/alarmsBtn.vue"

export default defineComponent({
  name: "topbar",
  components: {
    //KTNotificationsMenu, 
    //KTQuickLinksMenu,  
    alarmBtn,
    KTUserMenu,
  },
  setup(){
    const store = useStore();
    const user = computed(() => store.getters.currentUser)

    const masterId = computed(() => store.getters.curMasterId)
    const slaveId = computed(() => store.getters.curSlaveId)
    const addressId = computed(() => store.getters.curAddressId)
    const slaveTemplateId = computed(() => store.getters.curSlaveTemplateId)
    const addressTemplateId = computed(() => store.getters.curAddressTemplateId)

    

    return {
      user,
      masterId,
      slaveId,
      addressId,
      slaveTemplateId,
      addressTemplateId
    };
  }
});
