
import { defineComponent, onMounted,onUnmounted, ref, computed } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "alarms-btn",
  
  setup() {
   
    //const store = useStore();
    const { t} = useI18n();
    
  let updateAlarmsTimer: number;

  const alarmCount = ref({open:0,closed:0});


 const updateAlarms = () => {
 
      ApiService.get2("/device/alarmcount")
        .then(({ data }) => {
          // console.log("alarmcount response data:");
          // console.log(data);
          alarmCount.value = data;
          
        })
        
    }

    onMounted(() => {
             updateAlarmsTimer = setInterval(updateAlarms, 5000);
    });

    onUnmounted(() => {
      clearInterval(updateAlarmsTimer);
    });

    return {
      alarmCount,
      
      t,
    };
  },
});
