import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_2 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom",
  id: "kt_drawer_alarm_toggle"
}
const _hoisted_3 = {
  key: 0,
  class: "\r\n          bullet bullet-dot\r\n          bg-success\r\n          h-8px\r\n          w-8px\r\n          position-absolute\r\n          translate-middle\r\n          top-0\r\n          start-60\r\n          animation-blink\r\n        "
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass([{
                      'bg-danger': _ctx.alarmCount.open >0,
                      'bg-success': _ctx.alarmCount.open == 0,
                    }, "symbol-label fw-bolder bg-success text-inverse-info"])
      }, _toDisplayString(_ctx.alarmCount.open), 3),
      (_ctx.alarmCount.open > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ]))
}